<template>
  <div class="view pa24">
    <el-dialog
        :title="title"
        width="800px"
        :modal-append-to-body="false"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :before-close="closeDialog"
    >
      <div class="dialogBody">
        <el-form
            :model="ruleForm2"
            :rules="rules2"
            ref="orderRuleForm2"
            label-width="100px"
            class="ruleForm"
        >
          <el-form-item label="产品类别" prop="classId" name="classId">
            <el-cascader
                v-model="ruleForm2.classId"
                ref="cascader"
                :options="classOptions"
                :props="{
                  checkStrictly: true,
                  label:'name',
                  children: 'children',
                  value: 'id',
                  emitPath:false
              }"
                @change="classIdChange"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="箱数" prop="boxNum">
            <el-input-number v-model="ruleForm2.boxNum" :min="0"  @change="changeNum"></el-input-number>
          </el-form-item>
          <el-form-item label="盒数" prop="packNum">
            <el-input-number v-model="ruleForm2.packNum" :min="0"  @change="changeNum"></el-input-number>
          </el-form-item>
          <!-- <el-checkbox v-model="ruleForm2.isStrategy" :label="true" border size="small" @change="chooseStrategy" 
                       style="margin-left: 20px">推荐库位
          </el-checkbox> -->
        </el-form>
        <div>
          <el-table
              :data="strategyList"
              v-if="ruleForm2.isStrategy"
              style="width: 100%;margin-top: 10px;">
            <el-table-column
                prop="warehouseName"
                label="推荐库位"
                align="center"
                width="180">
            </el-table-column>
            <el-table-column
                prop="boxNum"
                align="center"
                label="箱数"
                width="180">
            </el-table-column>
            <el-table-column
                prop="packNum"
                align="center"
                label="盒数">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button class="btncss" @click="confirmAddRow">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {fetchAddOrUpdateDeal} from '@/api/companyManage.js'
import {getStrategyInfo, getOutStrategyInfo} from '@/api/crkStrategy'

export default {
  name: "ckOrderDialog",
  components: {},
  props: ['classOptions'],

  data() {
    return {
      dialogVisible: false,
      title: '添加订单',
      ruleForm2: {
        className: '',
        classId: 0,
        boxNum: 0,
        packNum: 0,
        isStrategy: false,
        strategyList:[],
        busName:'',
          goodsCode:'',
          specCode:'',
          specName:''
      },
      strategyList: [],
      rules2: {
        classId: [
          {required: true, message: '请选择产品类别', trigger: 'blur'},
        ],
      },
      type: 'add',
      editIndex: null,
      strategyInfo: '',
      hasSetting:false
    };
  },
  onShow() {

  },
  mounted() {
  },
  methods: {
    changeNum(){
      this.chooseStrategy()
    },
    getSetting() {
      getStrategyInfo({type: 2}).then(res => {
        if (res.data) {
          this.strategyInfo = res.data
        }
      })
    },
    chooseStrategy() {
      if (!this.ruleForm2.isStrategy) {
        return;
      }
      if (!this.ruleForm2.classId) {
        this.$message.error('请选择类别')
        this.ruleForm2.isStrategy = false
        return;
      }
      if (this.ruleForm2.boxNum == 0 && this.ruleForm2.packNum == 0) {
        this.$message.error('请填写箱数/盒数')
        this.ruleForm2.isStrategy = false
        return;
      }
      let params = {
        classId: this.ruleForm2.classId,
        boxNum: this.ruleForm2.boxNum,
        packNum: this.ruleForm2.packNum
      }
      getOutStrategyInfo(params).then(res => {
        // if (!res.data) {
        //   this.$message.error('库位内无该类产品')
        //   return
        // }
        if (res.data){
          this.strategyList = res.data
        }
      }).catch(e=>{
        console.log(e)
      })
    },
    openDialog(type, data, index,hasSetting) {
      this.hasSetting = hasSetting
      if (this.hasSetting){
        this.getSetting()
      }
      this.type = type
      this.editIndex = index ? index : null
      let v = this
      if (type === 'add') {
        this.title = '添加产品'
        this.ruleForm2 = {
          className: '',
          classId: 0,
          boxNum: 0,
          packNum: 0,
          isStrategy: false,
          busName:'',
          goodsCode:'',
          specCode:'',
          specName:''
        }
      } else {
        this.title = '编辑产品'
        // 回显数据
        this.getData(data)
      }
      this.dialogVisible = true
    },
    closeDialog() {
      this.dialogVisible = false
    },
    resetForm() {
      this.$refs.orderRuleForm2.resetFields();
    },
    // 回显
    getData(data) {
      this.ruleForm2 = {
        className: data.className,
        classId: data.classId,
        boxNum: data.boxNum,
        packNum: data.packNum,
        isStrategy: data.isStrategy,
        strategyList: data.strategyList,
      }
      if (data.goodsCode) {
        this.ruleForm2.goodsCode = data.goodsCode
      }
      if (data.specCode) {
        this.ruleForm2.specCode = data.specCode
      }
      if (data.specName) {
        this.ruleForm2.specName = data.specName
      }
      this.strategyList = this.ruleForm2.strategyList
    },
    classIdChange(e) {
      let node = this.$refs["cascader"].getCheckedNodes()[0]
      this.getClassName(node)
    },
    getClassName(data, str = '') {
      str += '/' + data.label
      if (data.data.busName) {
      this.ruleForm2.busName =data.data.busName
      }
      if (data.data.goodsCode) {
        this.ruleForm2.goodsCode = data.data.goodsCode
      }
      if (data.data.specCode) {
        this.ruleForm2.specCode = data.data.specCode
      }
      if (data.data.specName) {
        this.ruleForm2.specName = data.data.specName
      }
      if (data.parent) {
        this.getClassName(data.parent, str)
      } else {
        let res = str.split('/').reverse().join('/')
        this.ruleForm2.className = res.slice(0, -1)
        console.log('this.ruleForm.className', this.ruleForm2.className)
      }
    },
    confirmAddRow() {
      console.log('this.$refs.orderRuleForm2', this.$refs.orderRuleForm2)
      this.$refs.orderRuleForm2.validate((valid) => {
        if (!this.ruleForm2.classId) {
          this.$message.error('请选择类别')
          return
        }
        if(this.ruleForm2.boxNum == 0 && this.ruleForm2.packNum == 0){
          this.$message.error('请选择数量')
          return
        }
        console.log(this.type)
        if (valid) {
          if (this.ruleForm2.isStrategy){
            this.ruleForm2.strategyList = this.strategyList
          }
          if (this.type === 'add') {
            this.$emit('add', this.ruleForm2)
          } else {
            this.$emit('edit', this.ruleForm2, this.editIndex)
          }
          this.closeDialog()
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.ml10 {
  margin-left: 10 rpx;
}

.dialogBody {
  //max-height: 50vh;
  //overflow-y: scroll;
}
</style>
