import { render, staticRenderFns } from "./WarehouseStrategyDialog.vue?vue&type=template&id=af3dd372&scoped=true&"
import script from "./WarehouseStrategyDialog.vue?vue&type=script&lang=js&"
export * from "./WarehouseStrategyDialog.vue?vue&type=script&lang=js&"
import style0 from "./WarehouseStrategyDialog.vue?vue&type=style&index=0&id=af3dd372&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af3dd372",
  null
  
)

export default component.exports