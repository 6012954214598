<template>
  <div class="view pa24">
    <el-page-header @back="goBack" content="出库单" style="margin-bottom: 20px">
    </el-page-header>
    <el-form
          :model="ruleForm"
          :rules="rules"
          ref="orderRuleForm"
          label-width="100px"
          class="ruleForm"
      >
      <el-form-item label="出库单号" prop="lotNumber">
        <el-input v-model="ruleForm.lotNumber"  placeholder="请输入单号" style="width: 30%;"  :disabled="!showFlag"></el-input>
      </el-form-item>
        <el-form-item :label="labelName" prop="dealerId" v-if="showDealer">
          <el-select v-model="ruleForm.dealerId" placeholder="请选择" position="'bottom'" :disabled="!showFlag">
                  <el-option
                    v-for="item in dealerOptions"
                    :key="item.id"
                    :label="item.dealerName"
                    :value="item.id">
                  </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="区域" v-if="!showDealer">
          <el-input v-model="ruleForm.code" placeholder="请输入备注" style="width: 30%;" :disabled="!showFlag"></el-input>
        </el-form-item>
        <el-form-item label="出货仓库" prop="warehouseId" >
          <el-select v-model="ruleForm.warehouseId" placeholder="请选择" position="'bottom'" :disabled="!showFlag">
                  <el-option
                    v-for="item in warehouses"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remarks" >
          <el-input v-model="ruleForm.remarks" placeholder="请输入备注" style="width: 30%;" :disabled="!showFlag"></el-input>
        </el-form-item>
        <el-button type="primary" icon="el-icon-plus" style="margin-bottom: 20px" @click="handleAddRow"  v-if="showFlag">添加产品</el-button>
    </el-form>

    <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        prop="className"
        label="产品类别"
        align="center"
       >
      </el-table-column>
      <el-table-column
        prop="busName"
        label="商家名称"
        align="center"
       >
       <template slot-scope="scope">
        {{ scope.row.busName||'--' }}
       </template>
      </el-table-column>
      <el-table-column
        prop="goodsCode"
        label="货品编码"
        align="center"
       >
       <template slot-scope="scope">
        {{ scope.row.goodsCode||'--' }}
       </template>
      </el-table-column>

      <el-table-column
        prop="specCode"
        label="规格码"
        align="center"
       >
       <template slot-scope="scope">
        {{ scope.row.specCode||'--' }}
       </template>
      </el-table-column>
      <el-table-column
        prop="specName"
        label="规格名称"
        align="center"
       >
       <template slot-scope="scope">
        {{ scope.row.specName||'--' }}
       </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="boxNum"
        label="箱数（箱）"
        >
      </el-table-column>
      <el-table-column
       align="center"
        prop="packNum"
        label="盒数（盒）"
        >
      </el-table-column>
      <el-table-column
        align="center"
        prop="finishBoxNum"
        label="已完成数"
        v-if="!showFlag"
        >
        <template slot-scope="scope">
          <span :class="(dataStatus!== 0 && scope.row.finishBoxNum!==scope.row.boxNum) ? 'redColor' : ''">{{scope.row.finishBoxNum||0}}</span>箱 +
          <span :class="(dataStatus!== 0 && scope.row.finishPackNum!==scope.row.packNum) ? 'redColor' : ''">{{scope.row.finishPackNum||0}}</span>盒
          <!-- {{ scope.row.finishBoxNum}}（箱） + {{scope.row.finishPackNum }}（盒） -->
        </template>
      </el-table-column>
      <el-table-column align="center" width="200" label="操作"  >
          <template slot-scope="scope">
            <el-button type="text" @click="strategyLog(scope.row)" v-if="scope.row.isStrategy">查看推荐库位</el-button>
            <el-button type="text" @click="handleEditRow(scope.row, scope.$index)" v-if="showFlag">
              编辑
            </el-button>
            <el-button type="text" @click="deleteRow(scope.$index)" class="redColor" v-if="showFlag">删除</el-button>
            <el-button type="text" @click="lookLog(scope.row)" v-if="!showFlag">查看记录</el-button>
          </template>
        </el-table-column>

    </el-table>

    <div class="botBtns"  v-if="showFlag">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm">确认</el-button>
    </div>
    <!--添加弹窗-->
     <AddOrderDialog ref="addOrderDialog" :classOptions="classOptions" @add="addRow" @edit="editRow"></AddOrderDialog>
     <!---记录弹窗---->
     <LogOrderDialog ref="logOrderDialog"></LogOrderDialog>
<!--    策略推荐库位查看-->
  <warehouse-strategy-dialog ref="strategyDialog"></warehouse-strategy-dialog>

   </div>
</template>
<script>
import { pageClassList } from '@/api/customPageClass.js'
import { fetchDealList } from '@/api/companyManage.js'
import { fetchAddCkOrder, fetchCkOrderDetail,selectOrderLotNumber ,selectAllWarehouse} from '@/api/warehouseManage.js'
import AddOrderDialog from "./components/AddOrderDialog";
import LogOrderDialog from './components/LogOrderDialog'
import WarehouseStrategyDialog from "@/pages/ckOrder/components/WarehouseStrategyDialog.vue";
export default {
  data() {
    return {
      dealerOptions: [],
      classOptions:[],
      ruleForm: {
          dealerId: '',
          remarks: '',
        lotNumber:'',
        srcTradeNo:'',
        warehouseId:''
      },
      rules: {
        dealerId: [
          {required: true, message: this.source=='daili'?'请输入代理商名称':'请输入经销商名称', trigger: 'blur'},
        ],
        lotNumber: [
          {required: true, message: '请输入单号', trigger: 'blur'},
        ],
        warehouseId:[
        {required: true, message: '请选择出货仓库', trigger: 'blur'},
        ]
      },
      labelName: '',
      tableData: [],
      lotNumber: '',
      type:'',
      showFlag:true,
      dataStatus: '',
      hasSetting:false,
      showDealer:true,
      warehouses:[]
    }
  },
  components: {
    WarehouseStrategyDialog,
    AddOrderDialog,
    LogOrderDialog
  },
  created() {
    // 获取所有分类
    this.getClassList()
    // 获取所有代理商、经销商列表
    this.getDealerList()

    //
    let userInfo = JSON.parse(localStorage.getItem('info'));
    let roleType = userInfo.userType
    if(roleType == 3) { // 代理商,获取经销商列表
      this.labelName='经销商'
    }else if(roleType == 4) { // 经销商

    }else {
      this.labelName='代理商'
    }
    this.getWarehouse()
    if(this.$route.query.data) { // 编辑回显
      let data = JSON.parse(this.$route.query.data)
      console.log(data)
      this.id = data.id
      this.lotNumber = data.lotNumber
      this.ruleForm.remarks = data.remarks
      this.ruleForm.lotNumber = data.lotNumber
      this.ruleForm.dealerId = data.dealerId
      this.dataStatus = data.status
      this.ruleForm.srcTradeNo = data.srcTradeNo
      this.ruleForm.code = data.code
      this.ruleForm.warehouseId = data.warehouseId
      console.log('this.ruleForm.dealerId', this.ruleForm.dealerId)
      this.getOrderDetail()
      // this.ruleForm
    }else {
    this.getLotNumber()
    this.dataStatus = 0
    }
    this.hasSetting = this.$route.query.hasSetting
    let type = this.$route.query.type;
    if ('detail'==type){
      this.showFlag = false
      if(!this.ruleForm.dealerId){
        this.showDealer = false
      }
    }
  },
  methods: {
    getWarehouse(){
      selectAllWarehouse().then(res=>{
        this.warehouses = res.data
        if(!this.ruleForm.warehouseId && this.warehouses.length > 0){
            this.ruleForm.warehouseId = this.warehouses[0].id
        }
      })
    },
    getLotNumber(){
      selectOrderLotNumber().then(res=>{
        this.ruleForm.lotNumber = res.data
      })
    },
    goBack() {
      this.$router.push({
            path: '/crk/ckOrder'
          })
    },
    getOrderDetail() {
      let params = {
        orderId: this.id,
        pageNum: 1,
        pageSize: 9999,
      }
      fetchCkOrderDetail(params).then(res => {
        if(res.code === 200) {
          this.tableData = res.data.list
        }
      })
    },
    handleAddRow() {
      this.$refs.addOrderDialog.openDialog('add',null,null,this.hasSetting)
    },
    addRow(data) {
      this.tableData.push(data)
    },
    editRow(data,index) {
      this.tableData.splice(index,1, data)
    },
    lookLog(row) {
      this.$refs.logOrderDialog.openDialog(row, this.id)
    },
    strategyLog(row){
      this.$refs.strategyDialog.openDialog(row.strategyList)
    },
    handleEditRow(row,index) {
      this.$refs.addOrderDialog.openDialog('edit',row,index)
    },
    deleteRow(index) {
      this.tableData.splice(index, 1)
    },
    getClassList() {
        let params={
          pageNum: 1,
          pageSize: 999,
          type:0
        }
        pageClassList(params).then(res => {
          this.classOptions = this.formatData(res.data.list)
        })
      },
      // 过滤掉空的children
      formatData(data) {
        for (var i = 0; i < data.length; i++) {
          if (data[i].children == null || data[i].children.length == 0) {
            data[i].children = undefined;
          } else if (data[i].children.length > 1) {
            this.formatData(data[i].children);
          }
        }
        return data;
      },
      getDealerList() {
        let userInfo = JSON.parse(localStorage.getItem('info'));
        let roleType = userInfo.userType
        if(roleType == 3) {  // 代理商,获取经销商列表
          let params={
            pageNum: 1,
            pageSize: 999,
            type:2,
            companyId: JSON.parse(localStorage.getItem('info')).companyId,
          }
          fetchDealList(params).then(res => {
            this.dealerOptions = res.data.list
          })
        }else if(roleType == 4) { // 经销商

        }else { // 厂家，获取代理商
          let params={
            pageNum: 1,
            pageSize: 999,
            type:1,
            companyId: JSON.parse(localStorage.getItem('info')).companyId,
          }
          fetchDealList(params).then(res => {
            this.dealerOptions = res.data.list
          })
        }
      },
      cancel() {
        this.$router.push({
            path: '/crk/ckOrder'
          })
      },
      confirm() {
       this.$refs.orderRuleForm.validate((valid) => {
         if(valid) {
            if(this.tableData.length == 0) {
              this.$message.error('请选择产品类别')
              return
            }else {
               let params = {
                  dealerId: this.ruleForm.dealerId,
                  remarks: this.ruleForm.remarks,
                 lotNumber:this.ruleForm.lotNumber,
                 warehouseId:this.ruleForm.warehouseId
                }
                let reTable = JSON.parse(JSON.stringify(this.tableData))
                console.log(reTable)
                reTable.forEach(item => {
                  delete item.className
                })
                params.orderDetailDtos = reTable

                if(this.id) { // 有id 编辑
                  params.id = this.id
                }
                console.log('params',params)
                fetchAddCkOrder(params).then(res => {
                  if(res.code == 200) {
                    this.cancel()
                  }
                })
            }

         }
       })


      }

  },
}
</script>
<style scoped>
.redColor{
  color: #f00;
}
.botBtns{
  display: flex;
  padding-top: 20px;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fff;
  border-color: #E4E7ED;
  color: #000;
  cursor: not-allowed;
}
</style>
