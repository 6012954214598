var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view pa24"},[_c('el-dialog',{attrs:{"title":_vm.title,"width":"800px","modal-append-to-body":false,"visible":_vm.dialogVisible,"close-on-click-modal":false,"before-close":_vm.closeDialog},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"dialogBody"},[_c('el-form',{ref:"orderRuleForm2",staticClass:"ruleForm",attrs:{"model":_vm.ruleForm2,"rules":_vm.rules2,"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"产品类别","prop":"classId","name":"classId"}},[_c('el-cascader',{ref:"cascader",attrs:{"options":_vm.classOptions,"props":{
                checkStrictly: true,
                label:'name',
                children: 'children',
                value: 'id',
                emitPath:false
            }},on:{"change":_vm.classIdChange},model:{value:(_vm.ruleForm2.classId),callback:function ($$v) {_vm.$set(_vm.ruleForm2, "classId", $$v)},expression:"ruleForm2.classId"}})],1),_c('el-form-item',{attrs:{"label":"箱数","prop":"boxNum"}},[_c('el-input-number',{attrs:{"min":0},on:{"change":_vm.changeNum},model:{value:(_vm.ruleForm2.boxNum),callback:function ($$v) {_vm.$set(_vm.ruleForm2, "boxNum", $$v)},expression:"ruleForm2.boxNum"}})],1),_c('el-form-item',{attrs:{"label":"盒数","prop":"packNum"}},[_c('el-input-number',{attrs:{"min":0},on:{"change":_vm.changeNum},model:{value:(_vm.ruleForm2.packNum),callback:function ($$v) {_vm.$set(_vm.ruleForm2, "packNum", $$v)},expression:"ruleForm2.packNum"}})],1)],1),_c('div',[(_vm.ruleForm2.isStrategy)?_c('el-table',{staticStyle:{"width":"100%","margin-top":"10px"},attrs:{"data":_vm.strategyList}},[_c('el-table-column',{attrs:{"prop":"warehouseName","label":"推荐库位","align":"center","width":"180"}}),_c('el-table-column',{attrs:{"prop":"boxNum","align":"center","label":"箱数","width":"180"}}),_c('el-table-column',{attrs:{"prop":"packNum","align":"center","label":"盒数"}})],1):_vm._e()],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.closeDialog}},[_vm._v("取 消")]),_c('el-button',{staticClass:"btncss",on:{"click":_vm.confirmAddRow}},[_vm._v("确 定")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }