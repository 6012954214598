<template>
  <div class="view pa24">
    <el-dialog
        :title="title"
        width="800px"
        :modal-append-to-body="false"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :before-close="closeDialog"
    >
     <div class="dialogBody">
       <el-table :data="tableData" default-expand-all>
         <el-table-column type="expand">
           <template slot-scope="props">
             <div v-for="(item,index) in props.row.handles" :key="index" style="margin-bottom: 6px;margin-left: 20px">
               {{item.handle}}
               <el-tag>{{ item.type == 3 ? '箱' : '盒' }}</el-tag>
             </div>
           </template>
         </el-table-column>
         <el-table-column
             prop="warehouseName"
             align="center"
             label="推荐库位"
         />

         <el-table-column
             prop="boxNum"
             align="center"
             label="箱数"
         >
           <template slot-scope="scope">
             {{ scope.row.boxNum +'箱'}}
           </template>
         </el-table-column>
         <el-table-column
             prop="packNum"
             align="center"
             label="盒数"
         >
           <template slot-scope="scope">
             {{ scope.row.packNum +'盒'}}
           </template>
         </el-table-column>

       </el-table>
     </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "warehouseStrategyDialog",
  data() {
    return {
      dialogVisible: false,
      title: '推荐库位',
      tableData: [],
      total: 0,
      pageSize: 10,
      pageNum: 1,
      id:'',
      classId:'',
    };
  },
  components: {

  },
  onShow() {

  },
  methods: {
    openDialog(data) {
      this.tableData = data
      this.dialogVisible = true
    },
    closeDialog() {
      this.dialogVisible = false
    },
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.getList();
    },
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.ml10 {
  margin-left: 10 rpx;
}
.dialogBody{
  //max-height: 50vh;
  //overflow-y: scroll;
}
</style>
